import React from 'react'
import AbstractFormField from './AbstractFormField'
import { be } from '../../utilities/bliss'
import type { AbstractFormFieldStateType, DefaultFormFieldType } from './AbstractFormField'
import type { TranslatorType } from '../../types/FunctionTypes'
import type { ComponentSizesType, IconBaseType } from '../../types/ComponentTypes'

type Props = DefaultFormFieldType & {
  prefix: string | IconBaseType
  size: ComponentSizesType
  suffix: string | IconBaseType
  value: string
  onKeyPress: (name: string, value: string | number | boolean) => void
  t: TranslatorType
  autoComplete: string
  prefixText: string
}

type State = AbstractFormFieldStateType & {
  value: string
  prevValue: string
}

export default class String extends AbstractFormField<Props, State> {
  static defaultProps = {
    disabled: false,
    onChange: () => {},
    onErrorChange: () => {},
    required: true,
    type: 'text',
    prefix: '',
    size: null,
    suffix: '',
    placeholder: '',
    autoComplete: 'off',
    validationErrors: {},
  }

  state = {
    isPristine: true,
    value: '',
    prevValue: '',
  }

  static getDerivedStateFromProps(nextProps: Props, prevState: State) {
    if (prevState.prevValue !== nextProps.value) {
      return {
        value: nextProps.value,
        prevValue: nextProps.value,
      }
    }
    return null
  }

  handleOnChange = (event) => {
    const { onChange, name } = this.props
    const value = event.target.value
    this.setState({ value, isPristine: true })
    // send onChange if it's not just keyboard input
    // needed due to autofill on login
    if (event.nativeEvent.inputType !== 'insertText') {
      onChange(name, value, false)
    }
  }

  handleOnFocus = () => {}

  handleOnBlur = () => {
    const { onChange, name } = this.props
    const { value } = this.state
    onChange(name, value, false)
    this.setState({ isPristine: false })
    super.onBlur(value)
  }

  handleKeyPress = (e) => {
    const { onKeyPress, name, onChange } = this.props
    const { value } = this.state
    if (e.key === 'Enter') {
      onChange(name, value, false)
      onKeyPress(name, value)
    }
  }

  render() {
    const {
      autoComplete,
      disabled,
      textMode,
      id,
      name,
      readOnly,
      required,
      prefixText,
      t,
      placeholder,
      validationErrors,
    } = this.props
    let { type } = this.props
    const { value } = this.state
    if (textMode) {
      return (
        <div>
          <p>{value}</p>
        </div>
      )
    }

    if (['pid', 'zip', 'phone'].includes(type)) {
      type = 'text'
    }

    const label = t(`${prefixText}${name}.label`, {}, { fallback: '' })

    return (
      <div className="form-group">
        {label && (
          <label htmlFor={id}>
            {label}
            {required && <sup className={be('form-group', 'sup')}>*</sup>}
          </label>
        )}
        <input
          autoComplete={autoComplete}
          className={`form-control ${validationErrors[name] ? 'is-invalid' : ''}`}
          disabled={disabled}
          id={id}
          name={name}
          onBlur={this.handleOnBlur}
          onChange={this.handleOnChange}
          onFocus={this.handleOnFocus}
          onKeyPress={this.handleKeyPress}
          placeholder={t(`${prefixText}${name}.placeholder`, {}, { fallback: [`${name}.${placeholder}`, ''] })}
          readOnly={readOnly}
          required={required}
          type={type}
          value={value || ''}
        />
        {super.render()}
      </div>
    )
  }
}
