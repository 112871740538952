import React from 'react'
import AbstractFormField from './AbstractFormField'
import type { TranslatorType } from '../../types/FunctionTypes'
import type { AbstractFormFieldStateType, DefaultFormFieldType } from './AbstractFormField'

type TextPropsType = DefaultFormFieldType & {
  value: string
  t: TranslatorType
  prefix: string
  textClassName?: string
}

export default class Text extends AbstractFormField<TextPropsType, AbstractFormFieldStateType> {
  static defaultProps = {
    validationErrors: {},
    textClassName: 'lead',
  }

  render() {
    const { name, prefixText, t, prefix, textClassName } = this.props
    const text = t(`${prefix}${name}.text`, {}, { fallback: [`${prefixText}${name}.text`, ''] })

    return (
      <div className="form-group">
        <h2>{t(`${prefix}${name}.label`, {}, { fallback: [`${prefixText}${name}.label`, ''] })}</h2>
        {text && <p className={textClassName}>{text}</p>}
        {super.render()}
      </div>
    )
  }
}
