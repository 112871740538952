import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import Select from 'react-select'
import { be, bm } from '../../utilities/bliss'
import { selectFieldOptionsSelector } from '../../store/selectors/SelectFieldSelectors'
import type { TranslatorType } from '../../types/FunctionTypes'
import type { OnChangeValueType } from '../../types/GeneralStateType'
import { cn, isNewBrandingActive } from '@/src/rebrand/utilities'

type MinifiedSelectFieldType = {
  options?: any[]
  name: string
}

type SelectFieldOwnPropsType = MinifiedSelectFieldType & {
  reduxType?: string
  reduxPath?: string
  valueKey?: string
  labelKey?: string | string[]
  restrictedValues: any
}

type SelectFieldPropsType = MinifiedSelectFieldType & {
  t: TranslatorType
  prefixText: string
  options: any[]
  name: string
  onChange: OnChangeValueType
  id: string
  required: boolean
  disabled: boolean
  value: string | number | boolean
  restrictedValues: Object
  validationErrors: Object
}

const SelectField = ({
  options,
  name,
  prefixText,
  onChange,
  t,
  id,
  required,
  value,
  validationErrors,
  disabled,
  restrictedValues,
}: SelectFieldPropsType) => {
  const [selectedValue, setSelectedValue] = useState()

  const handleOnChange = (option) => {
    setSelectedValue(option)
    onChange(name, option[name], false)
  }

  useEffect(() => {
    if (restrictedValues[name] && disabled) {
      handleOnChange({ [name]: restrictedValues[name] })
    }
  }, [restrictedValues, name, disabled])

  useEffect(() => {
    if (Array.isArray(options)) {
      setSelectedValue(options.find((option) => option.value === value))
    }
  }, [options, value])

  const label = t(`${prefixText}${name}.label`, {}, { fallback: '' })
  return (
    <div>
      {label && (
        <label htmlFor={id}>
          {label}
          {required && <sup className={be('form-group', 'sup')}>*</sup>}
        </label>
      )}
      <Select
        className={bm(
          'react-select',
          ['searchable', validationErrors[name] ? 'is-invalid' : ''],
          cn('rounded-lg', isNewBrandingActive ? '' : 'border-2 border-solid border-light-brown'),
        )}
        classNamePrefix="react-select-searchable"
        isDisabled={disabled}
        isSearchable
        name={name}
        onChange={handleOnChange}
        options={options}
        placeholder={t(`${prefixText}${name}.placeholder`, {}, { fallback: '' })}
        value={selectedValue}
      />
    </div>
  )
}

const mapStateToProps = (
  state,
  { reduxType, reduxPath, options, name, labelKey, restrictedValues }: SelectFieldOwnPropsType,
) => {
  let newOptions = options || []
  let disabled = false
  if (reduxType && reduxPath) {
    newOptions = selectFieldOptionsSelector(state, reduxType, reduxPath)
  }
  if (labelKey) {
    newOptions = newOptions.map((item) => ({
      ...item,
      value: item[name],
      label: Array.isArray(labelKey)
        ? labelKey
            .map((label) => {
              if (label === 'email') {
                const emailPrefix = item[label]?.match(/^[^@]+/)[0]
                if (item['firstName'] !== emailPrefix) return `(${emailPrefix})`
                else return ''
              }
              return `${item[label]}`
            })
            .join(' ')
        : item[labelKey],
    }))
  }
  if (restrictedValues[name]) {
    disabled = true
  }
  return { options: newOptions, disabled }
}

export default connect(mapStateToProps)(SelectField)
