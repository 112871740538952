import React, { memo } from 'react'
import { convertNumberToVariety } from '../../utilities/helpers'
import type { TranslatorType } from '../../types/FunctionTypes'
import { be } from '../../utilities/bliss'
import { RadixButton } from '@/src/rebrand/buttonRadix'

type ButtonGroupPropsType = {
  name: string
  prefixText: string
  t: TranslatorType
  options: number[]
  onChange: (name: string, value: string | number | boolean, merge: boolean) => void
  variety?: number[]
  labels: any[]
  value: string | number | boolean
  prefix: string
}

const ButtonGroup: React.FC<ButtonGroupPropsType> = ({
  name = '',
  prefixText = '',
  prefix,
  t,
  labels,
  variety,
  onChange,
  value,
  ...props
}) => {
  let { options } = props
  if (Array.isArray(variety)) {
    options = convertNumberToVariety(variety)
  }

  const handleOnClick = (val: number | string) => {
    onChange(name, val, false)
  }

  return (
    <div>
      <div className={be('btn-group', 'title', [], 'row')}>
        {labels.map((label: number | string) => {
          const col = 12 / labels.length
          return (
            <div key={`button-grou-${name}-${label}`} className={be('btn-group', 'item-title', [], `col-${col}`)}>
              <strong>
                <span>{t(`${prefix}${name}.${label}`, {}, { fallback: `${prefixText}${name}.${label}` })}</span>
              </strong>
            </div>
          )
        })}
      </div>
      <div aria-label="group field" className="btn-group" role="group">
        {options.map((option: number | string) => (
          <RadixButton
            key={`button-grou-${name}-${option}`}
            className={`btn btn-group__item`}
            variant={option === value ? 'default' : 'white'}
            onClick={() => {
              handleOnClick(option)
            }}
          >
            {`${option}`}
          </RadixButton>
        ))}
      </div>
    </div>
  )
}

export default memo<ButtonGroupPropsType>(ButtonGroup)
