import React from 'react'
import AbstractFormField from './AbstractFormField'
import { bm } from '../../utilities/bliss'
import type { AbstractFormFieldStateType, DefaultFormFieldType } from './AbstractFormField'
import { isNewBrandingActive } from '@/src/rebrand/utilities'
import { RadixButton } from '@/src/rebrand/buttonRadix'

type Props = DefaultFormFieldType & {
  loading?: boolean
  onSubmit: () => void
  submitPrefix: string
}

export default class Submit extends AbstractFormField<Props, AbstractFormFieldStateType> {
  static defaultProps = {
    disabled: false,
    loading: false,
    prefix: '',
    size: null,
    suffix: '',
    validationErrors: {},
  }

  render() {
    const { id, name, t, prefixText, submitPrefix, onSubmit, loading } = this.props
    const text = t(
      `${submitPrefix}${name}.submit`,
      {},
      { fallback: [`${prefixText}${name}.submit`, `${name}.submit`, ''] },
    )
    const props = { disabled: loading, id, name, onClick: onSubmit, type: 'submit' } as const

    return (
      <div className="form-group">
        {isNewBrandingActive ? (
          <RadixButton {...props} loading={loading}>
            {text}
          </RadixButton>
        ) : (
          <input className={bm('btn', ['primary', loading ? 'loading' : ''], false, true)} {...props} value={text} />
        )}
      </div>
    )
  }
}
