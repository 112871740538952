import React from 'react'
import AbstractFormField from './AbstractFormField'
import { be, bm } from '../../utilities/bliss'
import type { AbstractFormFieldStateType, DefaultFormFieldType } from './AbstractFormField'
import { cn } from '@/src/rebrand/utilities'

type SwitchPropTypes = DefaultFormFieldType & {
  buttonStyle?: boolean
  listCol?: string
  large?: boolean
}

export default class Switch extends AbstractFormField<SwitchPropTypes, AbstractFormFieldStateType> {
  state = {
    isPristine: true,
  }

  static defaultProps = {
    onChange: () => {},
    onBlur: () => {},
    disabled: false,
    required: true,
    isPristine: false,
    large: false,
    validationErrors: {},
    validation: { isRequired: false },
    validate: null,
  }

  handleOnChange = (event) => {
    const { name, onChange } = this.props
    const value = event.target.checked
    onChange(name, value, false)
    super.onBlur(value)
    this.setState({ isPristine: true })
  }

  handleOnBlur = () => {
    this.setState({ isPristine: false })
  }

  render() {
    const { t, value, disabled, id, name, required, validationErrors, large, prefixText } = this.props
    const text = t(`${prefixText}${name}.text`, {}, { fallback: '' })
    return (
      <div className="form-group">
        <div
          className={`custom-control ${bm('custom-switch', [
            large ? 'large-switcher' : '',
            text && 'row',
            value ? 'off-checked' : 'on-checked',
          ])}`}
        >
          {text && <span className={be('custom-switch', 'text')}>{text}</span>}
          <label
            className={cn(
              `custom-control-label on`,
              value ? '' : 'active',
              !disabled && !value ? '!text-peach-500' : '',
            )}
            htmlFor={id}
          >
            {t(`${prefixText}${name}.off`, {}, { fallback: '' })}
          </label>
          <input
            checked={!!value}
            className={`custom-control-input ${validationErrors[name] ? 'is-invalid' : ''} `}
            disabled={disabled}
            id={id}
            name={name}
            onBlur={this.handleOnBlur}
            onChange={this.handleOnChange}
            required={required}
            type="checkbox"
          />
          <label
            className={cn("custom-control-label off before:bg-peach-500 before:content-['']", value ? 'active' : '')}
            htmlFor={id}
          >
            {t(`${prefixText}${name}.on`, {}, { fallback: '' })}
          </label>
        </div>
      </div>
    )
  }
}
