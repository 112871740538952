import AbstractFormField from './AbstractFormField'
import String from './String'
import Submit from './Submit'
import CheckBox from './CheckBox'
import CheckBoxList from './CheckBoxList'
import TextArea from './TextArea'
import Text from './Text'
import MarkdownHeading from './MarkdownHeading'
import SelectField from './SelectField'
import Switch from './Switch'
import IconTextField from './IconTextFields'
import ButtonText from '../ButtonText'

import { ButtonLink, ButtonGroup } from '../button'

// specific components
import DiagnosisServicesStep from '../diagnosis/DiagnosisServicesStep'
import DiagnosisGoalsStep from '../diagnosis/DiagnosisGoalsStep'
import DiagnosisInfoTip from '../diagnosis/DiagnosisInfoTip'

export default {
  default: AbstractFormField,
  string: String,
  email: String,
  password: String,
  submit: Submit,
  checkBox: CheckBox,
  checkBoxList: CheckBoxList,
  textArea: TextArea,
  text: Text,
  link: ButtonLink,
  buttonText: ButtonText,
  buttonGroup: ButtonGroup,
  markdownHeading: MarkdownHeading,
  select: SelectField,
  switch: Switch,
  iconText: IconTextField,
  servicesStep: DiagnosisServicesStep,
  purposesStep: DiagnosisGoalsStep,
  infoTip: DiagnosisInfoTip,
}
