import React from 'react'
import { connect } from 'react-redux'
import { pathOr, path as ramdaPath, mergeDeepRight, assocPath } from 'ramda'
import Router from 'next/router'
import * as Sentry from '@sentry/nextjs'
import specifications from '../../config/forms'
import FormValidator from '../../utilities/formValidator'
import Toaster from '../../utilities/toaster'
import UserActions from '../../redux/UserRedux'
import ClientActions from '../../redux/ClientRedux'
import ValidationActions from '../../redux/ValidationRedux'
import DiagnosisActions from '../../redux/DiagnosisRedux'
import FieldForm from './FieldForms'
import type { ReduxState } from '../../store/reducers'
import type { ErrorType, ValidationErrorType } from '../../types/ErrorTypes'
import type { TranslatorType } from '../../types/FunctionTypes'

export type FieldFilters = {
  [fieldName: string]: (data?: any) => boolean
}

type WrapperFieldPropTypes = {
  data?: any
  formName: string
  onChangeDiagnosisValue?: (path: string, value: any) => void
  onChangeUserValue?: (path: string, value: any) => void
  onChangeClientValue?: (path: string, value: any) => void
  mergeClientValuesInArray?: (path: string, value: any) => void
  mergeDiagnosisValuesInArray?: (path: string, value: any) => void
  onChange?: (path: string, value: any) => void
  onClick?: (event: any) => void
  onSubmit?: (data: any, isFake?: boolean) => void
  prefix?: string
  specificationName: string
  validationErrors?: ValidationErrorType
  reduxType?: string
  t: TranslatorType
  setValidationError?: (path: string[], value: ErrorType[] | {}) => void
  setValidationErrors?: (value: ErrorType[] | {}) => void
  className?: string
  loading?: boolean
  restrictedValues?: any
  fieldFilters?: FieldFilters
}

type WrapperFieldStateType = {
  validator: Object
  specification: Object
}

export class WrappedForm extends React.PureComponent<WrapperFieldPropTypes, WrapperFieldStateType> {
  state = {
    validator: null,
    specification: null,
  }

  static defaultProps = {
    onClick: () => {},
    setValidationErrors: () => {},
    setValidationError: () => {},
    onSubmit: () => {},
    onChange: () => {},
    onChangeUserValue: () => {},
    onChangeClientValue: () => {},
    mergeClientValuesInArray: () => {},
    mergeDiagnosisValuesInArray: () => {},
    reduxType: '',
    className: '',
    loading: false,
  }

  constructor(props: WrapperFieldPropTypes) {
    super(props)
    const specification = pathOr([], [props.specificationName], specifications)
    this.state = {
      specification,
      validator: new FormValidator(specification, props.fieldFilters),
    }
  }

  componentDidUpdate(prevProps: WrapperFieldPropTypes) {
    const { specificationName } = this.props
    if (prevProps.specificationName !== specificationName && specificationName) {
      const specification = pathOr([], [specificationName], specifications)
      this.setState({
        specification,
        validator: new FormValidator(specification),
      })
    }
  }

  handleOnChange = (name: string, value: string | number | boolean) => {
    const trimmedValue = typeof value === 'string' ? value.trim() : value
    const { onChangeUserValue, prefix, reduxType, onChangeClientValue, onChangeDiagnosisValue, onChange } = this.props
    const path: string = prefix ? `${prefix}${name}` : name
    if (reduxType === 'user' && typeof onChangeUserValue === 'function') {
      onChangeUserValue(path, trimmedValue)
    } else if (reduxType === 'client' && typeof onChangeClientValue === 'function') {
      onChangeClientValue(path, trimmedValue)
    } else if (reduxType === 'diagnosis' && typeof onChangeDiagnosisValue === 'function') {
      onChangeDiagnosisValue(path, trimmedValue)
    } else {
      onChange(path, trimmedValue)
    }
  }

  // Merge values in Array
  handleOnMerge = (name: string, value: string | number | boolean) => {
    const { prefix, reduxType, mergeClientValuesInArray, mergeDiagnosisValuesInArray } = this.props
    const path: string = prefix ? `${prefix}${name}` : name
    if (reduxType === 'client' && typeof mergeClientValuesInArray === 'function') {
      mergeClientValuesInArray(path, value)
    } else if (reduxType === 'diagnosis' && typeof mergeDiagnosisValuesInArray === 'function') {
      mergeDiagnosisValuesInArray(path, value)
    }
  }

  handleSubmit = (path: string | string[], value: string | number | boolean) => {
    const { onSubmit, setValidationErrors } = this.props
    let { data } = this.props
    if (Array.isArray(path) && typeof value !== 'undefined') {
      data = assocPath(path, value, data)
    }
    if (this.state.validator) {
      const [isValid, errors] = this.state.validator.validate(data)
      if (isValid) {
        setValidationErrors({})
        onSubmit(data)
      } else {
        Toaster.error('component.toaster.isInvalid')
        setValidationErrors(errors)
      }
    }
  }

  handleValidate = (validateAllData: boolean, path?: string[], value?: any) => {
    const { setValidationErrors, setValidationError } = this.props
    let { data } = this.props
    if (Array.isArray(path) && typeof value !== 'undefined') {
      data = assocPath(path, value, data)
    }
    if (this.state.validator) {
      const [isValid, errors] = this.state.validator.validate(data)
      if (validateAllData) {
        setValidationErrors(errors)
      } else if (path) {
        setValidationError(path, errors)
      }
      return isValid
    }
    return false
  }

  handleOnClick = (event: any, redirectUrl: string) => {
    const { onClick } = this.props
    if (redirectUrl) {
      Router.push(redirectUrl, redirectUrl).catch((err) => {
        Sentry.captureException(err)
      })
    } else if (typeof onClick === 'function') {
      onClick(event)
    }
  }

  render() {
    const { data, validationErrors, t, formName, prefix, className, loading, restrictedValues, fieldFilters } =
      this.props
    const { specification } = this.state
    return specification ? (
      <div className={`row ${className || ''}`}>
        <FieldForm
          data={data}
          formName={formName}
          loading={loading}
          onChange={this.handleOnChange}
          onClick={this.handleOnClick}
          onMerge={this.handleOnMerge}
          onSubmit={this.handleSubmit}
          prefix={prefix}
          restrictedValues={restrictedValues}
          specification={specification}
          t={t}
          validate={this.handleValidate}
          validationErrors={validationErrors}
          fieldFilters={fieldFilters}
        />
      </div>
    ) : null
  }
}

export const mapStateToProps = (state: ReduxState, ownProps: WrapperFieldPropTypes) => {
  const { prefix, data, reduxType } = ownProps
  const newData = prefix
    ? pathOr({}, [reduxType, ...prefix.split('.').filter((path) => path && path.length > 0)], state)
    : ramdaPath([reduxType], state)
  return {
    data: mergeDeepRight(newData, data || {}),
    validationErrors: pathOr({}, ['validation', 'validationErrors'], state),
  }
}

export const mapDispatchToProps = {
  mergeClientValuesInArray: ClientActions.mergeClientValuesInArray,
  mergeDiagnosisValuesInArray: DiagnosisActions.mergeDiagnosisValuesInArray,
  onChangeUserValue: UserActions.onChangeUserValue,
  onChangeClientValue: ClientActions.onChangeClientValue,
  onChangeDiagnosisValue: DiagnosisActions.onChangeDiagnosisValue,
  setValidationErrors: ValidationActions.setValidationErrors,
  setValidationError: ValidationActions.setValidationError,
}

export default connect(mapStateToProps, mapDispatchToProps)(WrappedForm)
