import React from 'react'
import type { AbstractFormFieldStateType, DefaultFormFieldType } from './AbstractFormField'
import AbstractFormField from './AbstractFormField'
import type { TranslatorType } from '../../types/FunctionTypes'
import IconText from '../icons/IconText'

type TextAreaPropsType = DefaultFormFieldType & {
  domain: {
    type: string
    pattern?: string
  }
  value: string
  prefixText: string
  additionalInfo: {
    icon: string
    iconTextClassName: string
  }
  className?: string
  rows: number
  t: TranslatorType
}

type TextAreaStateType = AbstractFormFieldStateType & {
  isPristine: boolean
  value: string
  prevValue: string
}

export default class TextArea extends AbstractFormField<TextAreaPropsType, TextAreaStateType> {
  state = {
    isPristine: true,
    value: '',
    prevValue: '',
  }

  static getDerivedStateFromProps(nextProps: TextAreaPropsType, prevState: TextAreaStateType) {
    if (prevState.prevValue !== nextProps.value) {
      return {
        value: nextProps.value,
        prevValue: nextProps.value,
      }
    }
    return null
  }

  static defaultProps = {
    prefixText: '',
    rows: 6,
    validationErrors: {},
    onChange: () => {},
  }

  handleOnChange = (event) => {
    this.setState({ value: event.target.value, isPristine: true })
  }

  handleOnBlur = () => {
    const { onChange, name } = this.props
    const { value } = this.state
    onChange(name, value, false)
    this.setState({ isPristine: false })
    super.onBlur(value)
  }

  render() {
    const { textMode, id, name, disabled, prefixText, rows, t, readOnly, required, additionalInfo, className } =
      this.props
    const { value } = this.state

    if (textMode) {
      return (
        <div>
          <p>{value}</p>
        </div>
      )
    }
    const label = t(`${prefixText}.${name}.label`, {}, { fallback: [`${prefixText}${name}.label`, ''] })

    return (
      <div className="form-group">
        {label && (
          <label htmlFor={id}>
            {label}
            {required && <sup className="text--danger ml--1">&#9679;</sup>}
          </label>
        )}
        <textarea
          className={`form-control ${className}`}
          disabled={disabled}
          id={id}
          name={name}
          onBlur={this.handleOnBlur}
          onChange={this.handleOnChange}
          placeholder={t(
            `${prefixText}.${name}.placeholder`,
            {},
            { fallback: [`${prefixText}${name}.placeholder`, ''] },
          )}
          readOnly={readOnly}
          rows={rows}
          value={value || ''}
        />
        {additionalInfo && (
          <IconText
            className={additionalInfo.iconTextClassName}
            icon={additionalInfo.icon}
            iconColor="primary"
            size="md"
            svgType="symbolic"
            text={t(
              `${prefixText}.${name}.additionalInfo`,
              {},
              { fallback: [`${prefixText}${name}.additionalInfo`, ''] },
            )}
          />
        )}
        {super.render()}
      </div>
    )
  }
}
