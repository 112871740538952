import React from 'react'
import Markdown from 'markdown-to-jsx'
import { Button } from './button'
import Icon from './icons/Icon'
import { be } from '../utilities/bliss'
import type { ButtonPropTypes } from './button/Button'
import { getTranslate } from 'src/utilities/i18n'

type ButtonTextPropTypes = ButtonPropTypes & {
  buttonText: string
  icon?: string
  onClick?: () => void
  name?: string
  prefixText?: string
  text: string
  redirectUrl?: string
  href?: string
  newWindow?: boolean
}

const ButtonText: React.FC<ButtonTextPropTypes> = React.forwardRef<any, ButtonTextPropTypes>(
  (
    { color, href, icon, buttonText, small, text, disabled, className, onClick, outline, redirectUrl, newWindow },
    ref,
  ) => {
    const { t } = getTranslate()

    const handleOnClick = () => {
      if (typeof onClick === 'function') {
        onClick()
      }
    }

    return (
      <div className={className}>
        <Button onClick={handleOnClick} {...{ newWindow, ref, redirectUrl, small, outline, href, disabled, color }}>
          {icon && <Icon className={be('btn', 'icon', false, true)} icon={icon} size="sm" svgType="symbolic" />}
          <span>{t(buttonText)}</span>
        </Button>
        <div className={be('btn', 'text', false, true)}>
          <Markdown className="small text-color-secondary">{t(text)}</Markdown>
        </div>
      </div>
    )
  },
)
ButtonText.displayName = 'ButtonText'

export default ButtonText
