import Link from 'next/link'
import type { ReactNode } from 'react'
import React from 'react'
import { UrlObject } from 'url'
import { bm } from '../../utilities/bliss'

export type ButtonPropTypes = {
  children?: string | ReactNode
  className?: string
  color?: 'primary' | 'light' | 'danger' | 'transparent' | 'dark' | 'brownies' | 'secondary' | 'white'
  disabled?: boolean
  href?: UrlObject | string
  newWindow?: boolean
  inline?: boolean
  link?: boolean
  onClick?: (event: any, redirectUrl?: string) => void
  outline?: boolean
  small?: boolean
  redirectUrl?: string
  loading?: boolean
  target?: string
  rel?: string
}

const Button: React.FC<ButtonPropTypes> = React.forwardRef<any, ButtonPropTypes>(
  (
    {
      children = '',
      className = '',
      color = 'primary',
      disabled = false,
      href = '',
      inline = false,
      link = false,
      onClick,
      outline = false,
      small = false,
      redirectUrl = '',
      loading = false,
      newWindow,
      target,
      rel,
    },
    ref,
  ) => {
    const classes = bm(
      link ? 'btn-link' : 'btn',
      inline ? 'inline' : [loading ? 'loading' : '', outline ? `outline-${color}` : color, small ? 'sm' : ''],
      className,
      true,
    )

    const handleOnClick = (event: any) => {
      if (typeof onClick === 'function') {
        onClick(event, typeof redirectUrl === 'string' ? redirectUrl : '')
      }
    }

    if (!children) {
      return null
    }

    return href ? (
      <Link
        href={href}
        target={target}
        rel={rel}
        aria-disabled={disabled}
        className={classes}
        {...(onClick ? { onClick: handleOnClick } : {})}
        {...(newWindow ? { rel: 'noopener noreferrer', target: '_blank' } : {})}
      >
        {children}
      </Link>
    ) : (
      <button className={classes} disabled={disabled || loading} onClick={handleOnClick} type="button" ref={ref}>
        {children}
      </button>
    )
  },
)
Button.displayName = 'Button'

export default Button
