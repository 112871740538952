import React from 'react'
import { pathOr } from 'ramda'
import type { ValidationErrorType } from '../../types/ErrorTypes'
import type { TranslatorType } from '../../types/FunctionTypes'

export type AbstractFormFieldPropType = {
  validate?: (a: string[], b: any) => any
  name: any
  validationErrors?: ValidationErrorType
  isPristine?: boolean
  t: TranslatorType
}

export type AbstractFormFieldStateType = {
  isPristine: boolean
}

export type DefaultFormFieldType = AbstractFormFieldPropType & {
  disabled?: boolean
  help?: string
  id?: string
  label?: Object
  link?: string
  onChange: (a: string, b: string | number | boolean, c: boolean) => void
  onErrorChange?: (string, Object) => void
  placeholder?: string
  prefixText?: string
  readOnly?: boolean
  required?: boolean
  textMode?: boolean
  type?: string
  value?: any
  validation?: { isRequired: boolean }
  wrapperClassName?: string
  checkRequiredData?: boolean
}

export default class AbstractFormField<
  Props extends AbstractFormFieldPropType,
  State extends AbstractFormFieldStateType,
> extends React.Component<Props, State> {
  onBlur(value: any) {
    const { validate, name } = this.props
    if (typeof validate === 'function') {
      validate(name.split('.'), value)
    }
  }

  static defaultProps = {
    validationErrors: {},
  }

  hasMessages = () => !!this.getMessages()

  getMessages = (): boolean | ValidationErrorType => {
    const { validationErrors, name, isPristine } = this.props
    if (this.state && this.state.isPristine && isPristine) {
      return false
    }
    const errors = validationErrors || {}
    const error: ValidationErrorType = pathOr(false, [name], errors)
    // @ts-expect-error bad typing
    if (error && error.length > 0) {
      return error
    }
    return false
  }

  getMessageFromValidationError = (validationError: ValidationErrorType) => {
    // @ts-expect-error badly written
    if (validationError && validationError.length > 0) {
      return {
        msg: validationError[0].result ? validationError[0].result.msg : '',
        data: validationError[0].result.data,
      }
    }
    return false
  }

  getMessageText = () => {
    const { t, name } = this.props
    const error = this.getMessageFromValidationError(this.getMessages())
    if (!error) {
      return ''
    }
    return t(`validationErrors.${name}.${error.msg}`, {}, { fallback: `validationErrors.${error.msg}` })
  }

  render() {
    return (
      this.hasMessages() && (
        <div className="invalid-feedback">
          <span>{this.getMessageText()}</span>
        </div>
      )
    )
  }
}
