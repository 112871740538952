import React from 'react'
import Markdown from 'markdown-to-jsx'
import AbstractFormField from './AbstractFormField'
import Icon from '../icons/Icon'
import { bm, be } from '../../utilities/bliss'
import type { AbstractFormFieldStateType, DefaultFormFieldType } from './AbstractFormField'

type CheckBoxPropTypes = DefaultFormFieldType & {
  buttonStyle?: boolean
  listCol?: string
  text?: string
  useTileStyles?: boolean
}

export default class CheckBox extends AbstractFormField<CheckBoxPropTypes, AbstractFormFieldStateType> {
  state = {
    isPristine: true,
  }

  static defaultProps = {
    buttonStyle: false,
    onChange: () => {},
    onBlur: () => {},
    onErrorChange: () => {},
    type: 'checkbox',
    disabled: false,
    required: true,
    isPristine: false,
    link: '',
    listCol: '',
    validationErrors: {},
    validation: { isRequired: false },
    validate: null,
    text: '',
    useTileStyles: false,
  }

  handleOnChange = (event: { target: HTMLInputElement }) => {
    const { name, onChange } = this.props
    const value = event.target.checked
    onChange(name, value, false)
    super.onBlur(value)
    this.setState({ isPristine: true })
  }

  handleOnBlur = () => {
    this.setState({ isPristine: false })
  }

  render() {
    const {
      buttonStyle,
      t,
      value,
      disabled,
      id,
      name,
      textMode,
      required,
      prefixText,
      link,
      listCol,
      validationErrors,
      text,
      useTileStyles,
    } = this.props
    if (textMode) {
      return (
        <div>
          <p>{t(value ? 'checked' : 'unchecked')}</p>
        </div>
      )
    }

    return (
      <div
        className={bm(
          'custom-checkbox',
          [buttonStyle ? 'button' : '', useTileStyles ? 'tile' : ''],
          `custom-control ${listCol ? `${listCol}` : ''}`,
        )}
      >
        <input
          checked={!!value}
          className={`custom-control-input ${validationErrors[name] ? 'is-invalid' : ''}`}
          disabled={disabled}
          id={id}
          name={name}
          onBlur={this.handleOnBlur}
          onChange={this.handleOnChange}
          required={required}
          type="checkbox"
        />
        <label className="custom-control-label" htmlFor={id}>
          <Markdown
            options={{
              overrides: {
                a: {
                  props: {
                    target: '_blank',
                  },
                },
              },
            }}
          >
            {text || t(`${prefixText}${name}`, { link, name }, { fallback: [`${prefixText}`, ''] })}
          </Markdown>
          {buttonStyle && <Icon className={be('custom-checkbox', 'icon')} icon="arrow" />}
        </label>
        {super.render()}
      </div>
    )
  }
}
