import React from 'react'
import { connect } from 'react-redux'
import { pathOr } from 'ramda'
import AbstractFormField from './AbstractFormField'
import CheckBox from './CheckBox'
import { convertStringToNumberIfInteger } from '../../utilities/textUtilities'
import type { DefaultFormFieldType } from './AbstractFormField'
import type { TranslatorType } from '../../types/FunctionTypes'
import type { ReduxState } from '../../store/reducers'

type CheckBoxListPropsType = DefaultFormFieldType & {
  buttonStyle: boolean
  values?: []
  singleValue: boolean
  onSubmit: () => void
  t: TranslatorType
  options: Record<string, any>
  keyAsValue: boolean
  optionsPath?: Object
  label?: Object
  listCol?: string
  prefix?: string
  useTileStyles?: boolean
  submitImmediately?: boolean
  className?: string
}

type CheckBoxListStateType = {
  isPristine: boolean
}

const getValues = (opts: any, keyAsValue: boolean) => {
  let options = opts
  if (typeof options === 'object' && keyAsValue) {
    options = Object.keys(options).reduce((acc, currentKey: any) => {
      acc.push({
        value: convertStringToNumberIfInteger(currentKey),
        label: options[currentKey],
      })
      return acc
    }, [])
  }
  return options
}

const getLabel = (options) => {
  if (Array.isArray(options)) {
    return options.reduce((acc, current) => {
      acc[`${current.value}`] = current.label
      return acc
    }, {})
  }
}

class CheckBoxList extends AbstractFormField<CheckBoxListPropsType, CheckBoxListStateType> {
  state = {
    isPristine: false,
  }

  static defaultProps = {
    buttonStyle: false,
    onChange: () => {},
    onErrorChange: () => {},
    type: 'checkboxList',
    prefixText: '',
    disabled: false,
    required: true,
    placeholder: '',
    isPristine: false,
    listCol: '',
    validationErrors: {},
    useTileStyles: false,
    submitImmediately: false,
    className: '',
  }

  handleOnChange = (name: string, value: string | number, isSingleton: boolean, submitImmediately: boolean = false) => {
    const { onChange, onSubmit } = this.props
    if (isSingleton) {
      onChange(name, value, false)
    } else {
      onChange(name, value, true)
    }
    if (submitImmediately) {
      onSubmit()
    }
  }

  render() {
    const {
      buttonStyle,
      options,
      t,
      prefixText,
      name,
      singleValue,
      value,
      placeholder,
      label,
      listCol,
      keyAsValue,
      prefix,
      useTileStyles,
      submitImmediately,
      className,
    } = this.props

    const title = t(`${prefixText}${name}.label`, {}, { fallback: '' })
    return (
      <div className={`checkbox-list ${className}`}>
        {title && <span className="lead">{title}</span>}
        <div className={`checkbox-list__wrapper ${listCol ? 'row no-gutters' : ''}`}>
          {options &&
            options.length > 0 &&
            options.map((option) => {
              const val = typeof option === 'object' && keyAsValue ? option.value : option
              let text = name
              if (prefix) {
                text = `${prefix}${name}.${val}`
              } else if (prefixText) {
                text = `${prefixText}${name}.`
              }
              return (
                <CheckBox
                  key={val}
                  buttonStyle={buttonStyle}
                  id={val}
                  label={t(`${prefixText}${name}.${val}`, {}, { fallback: [`${name}.${val}`, ''] })}
                  listCol={listCol}
                  name={option}
                  onChange={() => {
                    this.handleOnChange(`${name}`, val, singleValue, submitImmediately)
                  }}
                  placeholder={placeholder}
                  prefixText={text}
                  t={t}
                  text={label[val] || option.label || ''}
                  useTileStyles={useTileStyles}
                  value={
                    (singleValue && val === value) || (!singleValue && Array.isArray(value) && value.includes(val))
                  }
                />
              )
            })}
          {super.render()}
        </div>
      </div>
    )
  }
}

const mapStateToProps = (state: ReduxState, ownProps: CheckBoxListPropsType) => {
  const { options, optionsPath, keyAsValue } = ownProps
  let optionsToSet = options
  const path = optionsPath
  let label = {}
  if (Array.isArray(path)) {
    const reduxName = path[0]
    optionsToSet = getValues(pathOr([], [reduxName, path.slice(1)], state), keyAsValue)
    label = getLabel(optionsToSet)
  }
  return {
    label,
    options: optionsToSet,
  }
}

export default connect(mapStateToProps, {})(CheckBoxList)
