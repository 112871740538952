import ButtonComponent from './Button'
import ButtonLinkComponent from './ButtonLink'
import ButtonGroupComponent from './ButtonGroup'

export const Button = ButtonComponent
export const ButtonLink = ButtonLinkComponent
export const ButtonGroup = ButtonGroupComponent

export default {
  Button: ButtonComponent,
  ButtonLink: ButtonLinkComponent,
  ButtonGroup: ButtonGroupComponent,
}
