import React from 'react'
import CheckBoxList from '../formFields/CheckBoxList'
import { be } from '../../utilities/bliss'
import { getTranslate } from 'src/utilities/i18n'

enum DIAGNOSIS_GOAL {
  OTHER,
  COPING_WITH_FEELINGS,
  BALANCE,
  SELF_IMPROVEMENT,
}

const goals = (t) => [
  {
    value: DIAGNOSIS_GOAL.COPING_WITH_FEELINGS,
    label: t(`component.diagnosisGoals.betterMoods`),
  },
  {
    value: DIAGNOSIS_GOAL.BALANCE,
    label: t(`component.diagnosisGoals.manageStress`),
  },
  {
    value: DIAGNOSIS_GOAL.SELF_IMPROVEMENT,
    label: t(`component.diagnosisGoals.develop`),
  },
  {
    value: DIAGNOSIS_GOAL.OTHER,
    label: t(`component.diagnosisGoals.other`),
  },
]

type Props = {
  readonly onChange: (value: string) => void
  readonly onSubmit: () => void
}

const DiagnosisGoalsStep: React.FC<Props> = ({ onChange, onSubmit }) => {
  const { t } = getTranslate()

  return (
    <>
      <h2>
        <strong>{t(`diagnosisTest.goals.title.label`)}</strong>
      </h2>
      <p className="lead">{t(`diagnosisTest.goals.text.label`)}</p>
      <CheckBoxList
        buttonStyle
        className={be('diagnosis-services-step', 'services-list')}
        keyAsValue
        name="goal"
        onChange={onChange}
        onSubmit={onSubmit}
        options={goals(t)}
        singleValue
        submitImmediately
        t={t}
      />
    </>
  )
}

export default DiagnosisGoalsStep
