import React from 'react'
import AbstractFormField from './AbstractFormField'
import type { AbstractFormFieldStateType, DefaultFormFieldType } from './AbstractFormField'
import IconText from '../icons/IconText'
import type { IconPropsType } from '../icons/Icon'

type Props = DefaultFormFieldType & {
  className: string
  icon: IconPropsType
  reversed: boolean
  center: boolean
  textModifier: string
}

export default class IconTextField extends AbstractFormField<Props, AbstractFormFieldStateType> {
  render() {
    const { t, name, prefixText, className, icon, reversed, center, textModifier } = this.props
    return (
      <div className="form-group">
        <IconText
          center={center}
          className={className}
          icon={icon.name}
          iconColor={icon.color}
          reversed={reversed}
          size={icon.size}
          svgType={icon.svgType}
          text={t(`${prefixText}${name}.infoText`)}
          textModifier={textModifier}
        />
      </div>
    )
  }
}
