import React, { PureComponent } from 'react'
import FormFields from '../formFields/fields'
import FormValidator from '../../utilities/formValidator'
import type { DefaultFormFieldType } from '../formFields/AbstractFormField'
import type { TranslatorType } from '../../types/FunctionTypes'
import { FieldFilters } from './WrappedForm'
import { ValidationErrorType } from '../../types/ErrorTypes'
import { isFieldRendered } from '../../utilities/forms'
import Router from 'next/router'

type FieldFormProps = {
  data: Object
  onChange: (path: string, value: string | number | boolean) => void
  onMerge: (path: string, value: string | number | boolean) => void
  onClick: (event: any, redirectUrl: string) => void
  onErrorChange?: (string, Object) => void
  formName: string
  onSubmit: (name: string | string[], value: string | number | boolean) => void
  prefix: string
  specification: Array<DefaultFormFieldType>
  t: TranslatorType
  validate: (validateAllData: boolean, path: string[], value: number | string | boolean) => boolean
  validationErrors?: ValidationErrorType
  loading?: boolean
  restrictedValues?: Object
  fieldFilters?: FieldFilters
}

type FieldFormType = {
  isPristine: boolean
}

export default class FieldForm extends PureComponent<FieldFormProps, FieldFormType> {
  state = {
    isPristine: true,
  }

  static defaultProps = {
    data: {},
    onErrorChange: () => {},
    onSubmit: () => {},
    prefix: '',
    validationErrors: {},
    loading: false,
    restrictedValues: {},
  }

  handleKeyPress = (name: string, value: string | number | boolean) => {
    const { onSubmit } = this.props
    if (typeof onSubmit === 'function') {
      this.setState({ isPristine: false })
      onSubmit(name.split('.'), value)
    }
  }

  handleOnChange = (name: string, value: string | number | boolean, merging: boolean) => {
    const { onChange, onMerge } = this.props
    if (merging) {
      onMerge(name, value)
    } else {
      onChange(name, value)
    }
  }

  handleOnSubmit = (path: string | string[], value: string | number | boolean) => {
    this.props.onSubmit(path, value)
    this.setState({ isPristine: false })
  }

  handleOnClick = (event: any, redirectUrl: string = '') => {
    const { onClick } = this.props
    if (typeof onClick === 'function') {
      onClick(event, redirectUrl)
    }
  }

  showValidationErrors = (path: string[], value: any) => {
    const { validate } = this.props
    if (typeof validate === 'function') {
      validate(false, path, value)
    }
  }

  render() {
    const { data, specification, validationErrors, t, formName, prefix, loading, restrictedValues, fieldFilters } =
      this.props
    const { isPristine } = this.state
    const fieldNames: string[] = []

    if (!specification || !Array.isArray(specification)) {
      return <div>Specification must to be defined! Spec: cannot be empty</div>
    }

    const hideResetPasswordField = (spec) =>
      typeof window !== 'undefined' && spec.name === 'resetPassword' && Router.query?.token

    return specification.map((spec: DefaultFormFieldType) => {
      if (!spec.type) {
        return <div key="field-defined">Field type has to be defined! Field: {spec.name}</div>
      }
      if (spec.type !== 'radio' && fieldNames.includes(spec.name)) {
        return <div key="field-unique">Field name has to be unique! Field: {spec.name}</div>
      }
      if (!isFieldRendered(spec.name, fieldFilters, data)) return
      if (spec.checkRequiredData) {
        const validator = new FormValidator(specification)
        const [isValid] = validator.validate(data)
        if (!isValid) {
          return null
        }
      }
      fieldNames.push(spec.name)
      let Field = FormFields.default
      if (spec.type && FormFields[spec.type] && typeof spec.name === 'string') {
        Field = FormFields[spec.type]
      }

      let wrapperClassName = ['col']
      if (spec.wrapperClassName) {
        wrapperClassName = [spec.wrapperClassName]
      }

      const specValue = data[spec.name.split('.')]
      const value = specValue === undefined || specValue === null ? '' : specValue

      return (
        <div
          key={`wrapper-${spec.name}`}
          // ugly hack for fixed button at the bottom
          className={`${spec.type === 'submit' ? 'fixed-button' : ''} ${
            hideResetPasswordField(spec) ? 'hidden' : ''
          } ${wrapperClassName.join(' ')}`}
        >
          {spec.label && spec.type === 'link' && <span>{t(`${prefix}${formName}${spec.name}.buttonLabel`)}</span>}
          <Field
            {...spec}
            key={`input-${spec.name}`}
            id={spec.id || `${prefix}${formName}${spec.name}`}
            isPristine={isPristine}
            loading={loading}
            onChange={this.handleOnChange}
            onClick={this.handleOnClick}
            onKeyPress={this.handleKeyPress}
            onSubmit={this.handleOnSubmit}
            prefixText={`${prefix}${formName}`}
            required={!!(spec.validation && spec.validation.isRequired)}
            restrictedValues={restrictedValues}
            t={t}
            validate={this.showValidationErrors}
            validationErrors={validationErrors}
            value={value}
          />
        </div>
      )
    })
  }
}
