import { FieldFilters } from '../components/formComponents/WrappedForm'
import { TranslatorType } from '../types/FunctionTypes'
import { ServiceType } from '../types/GeneralStateType'
import capitalize from 'lodash/capitalize'
import { OptionType } from '../components/formFields/types'
import { SERVICES_TYPE } from '@/src/redux/DiagnosisRedux'
import { InsuranceCompany } from '@/src/components/insuranceCompanies/types'

export const isFieldRendered = (key: string, fieldFilters: FieldFilters, data: Record<string, any>) =>
  fieldFilters && fieldFilters[key] ? fieldFilters[key](data) : true

export type ServiceOption = ServiceType & OptionType

export const generateServiceOptions = (services: ServiceType[], t: TranslatorType): ServiceOption[] =>
  services.map((service) => ({
    ...service,
    value: service.id,
    label: t('component.serviceButton.label', {
      label: capitalize(service.name),
      duration: service.duration,
    }),
  }))

export const mapServicesForCheckboxes = (services: ServiceType[]) => {
  return services
    ?.filter((item) => item.name && (item.type === SERVICES_TYPE.MAIN || item.type === SERVICES_TYPE.MINOR))
    .map((item) => {
      return {
        label: capitalize(item.name),
        value: `${item.id}`,
        icon: item.iconName || 'user',
        description: item.description,
      }
    })
}

export const mapInsuranceForCheckboxes = (insurances: InsuranceCompany[]) => {
  return insurances.map((item) => {
    return {
      label: item.name,
      value: `${item.id}`,
      image: item.logo,
    }
  })
}
