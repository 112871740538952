import React from 'react'
import { bm } from '../../utilities/bliss'
import Link from 'next/link'
import { UrlObject } from 'url'
import { getTranslate } from 'src/utilities/i18n'

type ButtonLinkPropsType = {
  className?: string
  disabled?: boolean
  inverted?: boolean
  name?: string
  onClick?: (event: any, redirectUrl: string) => void
  prefixText?: string
  redirectUrl?: string
  text: string
  newWindow?: boolean
  href?: UrlObject | string
  disableTranslate?: boolean
  isWebview?: boolean
}

const ButtonLink: React.FC<ButtonLinkPropsType> = ({
  className = '',
  disableTranslate = false,
  disabled = false,
  name = '',
  inverted = false,
  onClick = () => {},
  prefixText = '',
  redirectUrl = '',
  text = '',
  href = '',
  newWindow = false,
  isWebview = false,
}) => {
  const { t } = getTranslate()

  const handleOnClick = (event: any) => {
    const url = redirectUrl || t(`${prefixText}${name}.href`, {}, { fallback: '' })
    if (url.includes('http') && !isWebview) {
      if (newWindow || event.metaKey || event.ctrlKey) {
        window.open(url)
      } else {
        window.location = url as any
      }
    } else {
      onClick(event, url)
    }
  }
  const content = disableTranslate
    ? text
    : name
      ? t(`${prefixText}${name}.buttonText`, {}, { fallback: text })
      : t(text)

  return href ? (
    <Link
      href={href}
      className={bm('button-link', inverted && 'inverted', className)}
      {...(newWindow ? { target: '_blank', rel: 'noreferrer' } : {})}
    >
      {content}
    </Link>
  ) : (
    <button
      className={bm('button-link', inverted && 'inverted', className)}
      disabled={disabled}
      name={name}
      onClick={handleOnClick}
      type="button"
    >
      {content}
    </button>
  )
}

export default ButtonLink
