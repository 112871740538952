import { path } from 'ramda'
import { FieldFilters } from '../components/formComponents/WrappedForm'
import { isFieldRendered } from './forms'
import ValidationRules from './validationRules'

export default class FormValidator {
  formConfig = {}
  formConfigKeys = []
  allDataValid = false
  onlyValidData = {}
  errorMessages = {}
  fieldFilters = {}

  constructor(formConfig: Object, fieldFilters?: FieldFilters) {
    this.setConfig(formConfig, fieldFilters)
  }

  processConfigArray = (configArray: any[], initValue: any) =>
    configArray.reduce((acc2, formItem) => {
      if (!formItem.disabled) {
        acc2[formItem.name] = formItem
      }
      return acc2
    }, initValue)

  setConfig = (formConfig: any, fieldFilters?: FieldFilters) => {
    this.formConfig = this.processConfigArray(formConfig, {})
    this.formConfigKeys = Object.keys(this.formConfig)
    this.fieldFilters = fieldFilters
    return this
  }

  isValid = () => this.allDataValid

  getOnlyValidData = () => this.onlyValidData

  getErrorMessages = () => this.errorMessages

  validate = (data: Object) => {
    this.allDataValid = true
    this.errorMessages = {}
    this.onlyValidData = this.formConfigKeys.reduce(
      (validData, formItemName) => {
        const formItem = this.formConfig[formItemName]
        const value = path([formItemName], data)

        if (formItem.validation && isFieldRendered(formItemName, this.fieldFilters, data)) {
          const validationKeys = Object.keys(formItem.validation)
          let isAndCanBeEmpty = false
          if (formItem.validation.canBeEmpty) {
            isAndCanBeEmpty = ValidationRules.canBeEmpty(value)
          }
          const inputErrors = validationKeys.reduce((errors, validationRule) => {
            const validationConfigValue = formItem.validation[validationRule]
            if (!isAndCanBeEmpty && validationRule !== 'canBeEmpty') {
              const result = ValidationRules[validationRule]
                ? ValidationRules[validationRule](value, validationConfigValue, data)
                : 'validationErrors.notValid'
              if (result !== true) {
                errors.push({ rule: validationRule, result })
                this.allDataValid = false
              }
            }
            return errors
          }, [])
          if (inputErrors.length === 0 && value !== 'undefined') {
            validData[formItemName] = formItem.validation.isNumber && value.length === 0 ? null : value
          } else {
            this.errorMessages[formItemName] = inputErrors
          }
        } else {
          validData[formItemName] = value
        }
        return validData
      },
      {} as Record<string, unknown>,
    )

    return [this.allDataValid, this.errorMessages]
  }
}
