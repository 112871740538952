import React from 'react'
import { getTranslate } from 'src/utilities/i18n'
import Icon from '../icons/Icon'

const DiagnosisInfoTip = () => {
  const { t } = getTranslate()

  return (
    <p className="flex items-center py-10 text-sm text-secondary tablet:justify-center">
      <Icon icon="info" size="md" className="mr-3" />
      {t(`diagnosisTest.diagnosisV3.infoTip`)}
    </p>
  )
}

export default DiagnosisInfoTip
