import therapistDiagnosisLeadSpec from './therapistDiagnosis/therapistDiagnosisLeadSpec.json'
import therapistDiagnosisPastSpec from './therapistDiagnosis/therapistDiagnosisPastSpec.json'
import therapistDiagnosisPersonalitySpec from './therapistDiagnosis/therapistDiagnosisPersonalitySpec.json'
import therapistDiagnosisEmotionsSpec from './therapistDiagnosis/therapistDiagnosisEmotionsSpec.json'
import therapistDiagnosisSexSpec from './therapistDiagnosis/therapistDiagnosisSexSpec.json'
import loginSpec from './loginSpec.json'
import feedbackSpec from './feedback/feedbackSpec.json'
import feedbackSpecEdit from './feedback/feedbackSpecEdit.json'
import feedbackClientHappySpec from './feedback/feedbackClientHappySpec.json'
import feedbackClientHappySpecEdit from './feedback/feedbackClientHappySpecEdit.json'
import feedbackClientNeutralSpec from './feedback/feedbackClientNeutralSpec.json'
import feedbackClientUnhappySpec from './feedback/feedbackClientUnhappySpec.json'
import feedbackClientUnhappyMessageSpec from './feedback/feedbackClientUnhappyMessageSpec.json'
import resetPasswordSpec from './reset/resetPasswordSpec.json'
import changePasswordSpec from './reset/changePasswordSpec.json'
import feedbackClientHappyFinishSpec from './feedback/feedbackClientHappyFinishSpec.json'
import prebookingSpecification from './therapist/prebookingSpecification.json'
import voucherSpec from './voucher/voucherSpec.json'

export default {
  loginSpec,
  feedbackSpec,
  feedbackSpecEdit,
  feedbackClientHappySpec,
  feedbackClientHappySpecEdit,
  feedbackClientNeutralSpec,
  feedbackClientUnhappySpec,
  feedbackClientUnhappyMessageSpec,
  resetPasswordSpec,
  changePasswordSpec,
  feedbackClientHappyFinishSpec,
  voucherSpec,
  prebookingSpecification,
  therapistDiagnosisLeadSpec,
  therapistDiagnosisPastSpec,
  therapistDiagnosisPersonalitySpec,
  therapistDiagnosisEmotionsSpec,
  therapistDiagnosisSexSpec,
}
