import dayjs, { Dayjs } from 'dayjs'
import weekday from 'dayjs/plugin/weekday'
import { CALENDAR_END_HOUR, CALENDAR_START_HOUR } from '../constants/date.constants'

dayjs.extend(weekday)

export const daysGenerator = () => {
  dayjs.locale(process.env.NEXT_PUBLIC_LOCALE || 'cs')
  const tomorrow = dayjs().add(1, 'day')
  const days: Dayjs[] = []
  for (let i = 0; i <= 6; i++) {
    days.push(dayjs(tomorrow).add(i, 'days'))
  }
  return days
}

export const timesGenerator = (counter: number = 3) => {
  const times: string[] = []
  let startHour = CALENDAR_START_HOUR
  const endHour = CALENDAR_END_HOUR
  const timesLength = (endHour - startHour) / counter
  for (let i = 0; i < timesLength; i++) {
    const end = startHour + counter > endHour ? endHour : startHour + counter
    const time = `${startHour}:00 - ${end}:00`
    times.push(time)
    startHour += counter
  }
  return times
}

export const convertNumberToVariety = (variety: number[]) => {
  const from = variety[0]
  const last = variety[variety.length - 1]
  const number: number[] = []
  for (let i = from; i <= last; i++) {
    number.push(i)
  }
  return number
}
