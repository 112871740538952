import React, { useEffect, useState } from 'react'
import CheckBoxList from '../formFields/CheckBoxList'
import { diagnosisServices } from '../../store/selectors/GeneralSelectors'
import { be } from '../../utilities/bliss'
import { SERVICES_TYPE } from '../../redux/DiagnosisRedux'
import { useAppSelector } from '../../store/hooks'
import { getTranslate } from 'src/utilities/i18n'
import { RadixButton } from '@/src/rebrand/buttonRadix'

const componentName = 'diagnosis-services-step'

const DiagnosisServicesStep = ({ onChange, onSubmit }) => {
  const { t } = getTranslate()

  const services = useAppSelector(diagnosisServices)

  const [showMinorServices, setShowMinorServices] = useState(false)
  const [options, setOptions] = useState<any>()
  const minorServicesExist = Array.isArray(services[SERVICES_TYPE.MINOR])

  useEffect(() => {
    if (!options) {
      setOptions(services[SERVICES_TYPE.MAIN])
    }
  }, [services])

  const handleShowMinorServices = () => {
    if (!showMinorServices && minorServicesExist) {
      setOptions((prevState) => [...prevState, ...services[SERVICES_TYPE.MINOR]])
    } else {
      setOptions(services[SERVICES_TYPE.MAIN])
    }
    setShowMinorServices(!showMinorServices)
  }

  return (
    <>
      <CheckBoxList
        buttonStyle
        className={be(componentName, 'services-list')}
        keyAsValue
        name="service"
        onChange={onChange}
        onSubmit={onSubmit}
        options={options}
        singleValue
        submitImmediately
        t={t}
      />
      {minorServicesExist && (
        <div className={be(componentName, 'show-more')}>
          <RadixButton variant="inline" onClick={handleShowMinorServices}>
            {t(`component.button.${showMinorServices ? 'showLess' : 'showMoreServices'}`)}
          </RadixButton>
        </div>
      )}
    </>
  )
}

export default DiagnosisServicesStep
